import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button as ReferaButton, DatePicker } from '@refera/ui-web'
import Button from '_components/button'
import Accordion from '_/components/accordion'
import { Select, DateTimePicker } from '_/components/inputs'
import * as Input from '_/components/inputs/Input'
import { useToast } from '_/hooks/use-toast'
import SwitchComponent from '_/components/switch'
import {
  Select as MultiSelect,
  InputLabel,
  Typography,
  IconButton,
  InputAdornment,
  Tooltip,
  MenuItem,
  FormControl,
  Grid,
} from '@material-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { FormHelperText, TextField } from '@mui/material'
import { userSelector } from '_/modules/authentication/selectors'
import {
  castToStringifiedNumber,
  CONTRACT_STATUS_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  AGENCY_REFERA_DETAILS_FIELDS,
  transformValue,
} from '../components/AgencyReferaDetailsModal/constants'
import { navigate, useParams } from '@reach/router'
import HeaderTitle from '_components/header-title'
import { agencySelector } from '_/modules/agency/selectors'
import { getConnectionSelector } from '_modules/provider/selectors'
import { getAgency, updateAgency } from '_/modules/agency/actions'
import { METHOD_PAYMENT_OPTIONS_SELECT } from '_/views/finance/manage-installments/utils/constants'
import { useDialog } from '_/hooks/use-dialog'

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import ClearIcon from '@material-ui/icons/Clear'
import moment from 'moment'
import useStyles from './styles'

const agencyReferaDetailsFieldTypes = Object.entries(AGENCY_REFERA_DETAILS_FIELDS).reduce(
  (acc, [, value]) => {
    acc[value.name] = value.type
    return acc
  },
  {}
)

const transformPayloadValues = data => {
  const transformedData = Object.entries(data).reduce((acc, [key, value]) => {
    switch (agencyReferaDetailsFieldTypes[key]) {
      case 'number': {
        acc[key] = value ? castToStringifiedNumber(value) : null
        break
      }
      case 'datetime': {
        try {
          if (!value) {
            acc[key] = null
            break
          }

          const formattedDate = moment(value).toISOString()
          acc[key] = formattedDate
        } catch (error) {
          throw new Error('Data inválida')
        }
        break
      }
      case 'date': {
        try {
          if (!value) {
            acc[key] = null
            break
          }
          const formattedDate = moment(value).format('YYYY-MM-DD')
          acc[key] = formattedDate
        } catch (error) {
          throw new Error('Data inválida')
        }
        break
      }
      case 'multiselect': {
        acc[key] = value.map(item => ({ name: item }))

        break
      }
      default: {
        acc[key] = value
        break
      }
    }

    return acc
  }, {})
  return transformedData
}

const ReferaDataScreen = () => {
  const styles = useStyles()
  const agency = useSelector(agencySelector)?.toJS()
  const dispatch = useDispatch()
  const { agencyId } = useParams()
  const connectionsList = useSelector(getConnectionSelector)
  const user = useSelector(userSelector)
  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()
  const [selectedMethods, setSelectedMethods] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedFlows, setSelectedFlows] = useState([])

  const handleChangeFlow = event => {
    const { value } = event.target
    setSelectedFlows(value)
    methods.setValue(AGENCY_REFERA_DETAILS_FIELDS.FLOWS.name, value)
  }

  const formattedPaymentMethods = agency?.paymentMethods?.map(method => method?.option) || []

  const handleChange = event => {
    const { value } = event.target
    setSelectedMethods(value)
    methods.setValue(AGENCY_REFERA_DETAILS_FIELDS.PAYMENT_METHODS.name, value)
  }

  const handleBackNavigation = useCallback(() => {
    navigate(-1)
  }, [])

  const renderInput = useCallback(params => <TextField variant="standard" {...params} />, [])
  const whatsappConnectionOptions = useMemo(
    () => connectionsList?.slice()?.sort((a, b) => a.id - b.id) || [],
    [connectionsList]
  )

  const defaultValues = useMemo(() => {
    if (!agency) {
      return {}
    }

    const filteredValues = Object.entries(AGENCY_REFERA_DETAILS_FIELDS).reduce(
      (acc, [key, value]) => {
        acc[value.name] = transformValue(agency[value.name], key)
        return acc
      },
      {}
    )
    return filteredValues
  }, [agency])

  const methods = useForm({
    mode: 'all',
  })

  const { handleSubmit } = methods

  const handleSubmitData = useCallback(
    async data => {
      setLoading(true)

      let transformedPayload

      try {
        const updatedData = { ...data, isReferaData: true }
        transformedPayload = transformPayloadValues(updatedData)
      } catch (error) {
        showToast({
          type: 'error',
        })
        setLoading(false)
        return
      }

      dispatch(updateAgency(transformedPayload, false))
        .then(() => {
          showToast({
            type: 'success',
          })
          setLoading(false)
        })
        .catch(() => {
          showToast({
            type: 'error',
          })
          setLoading(false)
        })
    },
    [showToast, dispatch]
  )

  const onSubmit = async data => {
    const changeTypeContractToSAAS =
      agency?.contractType !== 'SAAS' && data?.contractType === 'SAAS'

    const changeTypeContractToNotSAAS =
      agency?.contractType === 'SAAS' && data?.contractType !== 'SAAS'

    if (changeTypeContractToSAAS || changeTypeContractToNotSAAS) {
      showDialog({
        type: 'warning',
        subject:
          'Esta ação irá mudar todos os perfis de acesso dos usuários desta intermediária, tornando-os todos administradores. Você confirma esta ação?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => {
          handleSubmitData(data)
          closeDialog()
        },
        onCancel: closeDialog,
      })
    } else {
      handleSubmitData(data)
    }
  }

  useEffect(() => {
    if (agencyId) {
      dispatch(getAgency(agencyId))
    }
  }, [agencyId])

  const renderContractStatusClearButton = useCallback(() => {
    if (!methods.getValues(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name)) return null

    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() => methods.setValue(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name, null)}
          style={{ padding: '4px', marginRight: '20px' }}
        >
          <ClearIcon
            fontSize="small"
            style={{
              fill: '#B4B4B4',
            }}
          />
        </IconButton>
      </InputAdornment>
    )
  }, [methods.watch(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name)])

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={e => e.preventDefault()}>
        <HeaderTitle
          title={`Dados da Refera / Intermediária ${agency?.name || ''}`}
          backButtonAction={handleBackNavigation}
        >
          <Grid className={styles.headerButtons}>
            {methods.watch(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name) !== 'cancelled' && (
              <Button
                className={styles.button}
                style={{ backgroundColor: 'red', color: 'white' }}
                variant="ghost"
                onClick={() => {}}
              >
                Solicitar rescisão
              </Button>
            )}
            <ReferaButton className={styles.button} color="red" variant="secondary">
              Cancelar
            </ReferaButton>
            <Button
              className={styles.button}
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Grid>
        </HeaderTitle>
        <Grid className={styles.container}>
          <Accordion title="Informações do Contrato" className={styles.accordion}>
            <div className={styles.column}>
              <div className={styles.row}>
                <Select
                  label="Tipo de contrato"
                  name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_TYPE.name}
                  options={CONTRACT_TYPE_OPTIONS}
                  getLabel={item => item.label}
                  getValue={item => item.value}
                  defaultValue={agency?.contractType}
                  disabled={user?.activeProfile === 'intermediary'}
                />
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.row}>
                <Select
                  label="Status do contrato"
                  name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name] ?? null
                  }
                  getLabel={item => item.label}
                  getValue={item => item.value}
                  endAdornment={renderContractStatusClearButton()}
                  options={CONTRACT_STATUS_OPTIONS}
                  disabled={user?.activeProfile === 'intermediary'}
                />
              </div>
            </div>
            <br />
            <div className={styles.switchInputGroup}>
              <label
                className={styles.switchLabel}
                htmlFor={AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name}
              >
                Agência VIP
              </label>
              <Controller
                control={methods.control}
                id={AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name}
                name={AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name]}
                render={props => (
                  <SwitchComponent
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
            <div className={styles.row}>
              <Input.Root
                name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name]}
              >
                <Input.Label
                  labelClasses={styles.labelNumberInput}
                  name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT}
                >
                  Limite de VIPs
                </Input.Label>
                <Input.ControllerNumber
                  name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name}
                  decimalScale={0}
                />
                <Input.ErrorMessage name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name} />
              </Input.Root>
            </div>
          </Accordion>
          <Accordion title="Datas do Contrato" className={styles.accordion}>
            <DateTimePicker
              label="Data de geracão do contrato"
              name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_GENERATION_DATE.name}
              defaultValue={
                defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_GENERATION_DATE.name] ?? null
              }
              rules={{}}
              className={styles.dateTimePicker}
              referaDatePickerProps={{ disabled: true }}
            />
            <div className={styles.datePickerInputGroup}>
              <InputLabel
                variant="standard"
                id={`${AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE.name}-label`}
                shrink
                className={styles.datePickerLabel}
              >
                Data de assinatura do contrato
              </InputLabel>
              <Controller
                control={methods.control}
                name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE.name}
                defaultValue={
                  defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE.name] ?? null
                }
                render={field => {
                  return (
                    <DatePicker
                      variant="inline"
                      mask="__/__/____"
                      format="dd/MM/yyyy"
                      refuse={/[^\d.]+/gi}
                      renderInput={renderInput}
                      disabled
                      {...field}
                    />
                  )
                }}
              />

              <FormHelperText
                className={styles.errorMessage}
                error={!!methods.errors?.contractSignatureDate}
              >
                {methods.errors?.contractSignatureDate?.message}
              </FormHelperText>
            </div>
            <DateTimePicker
              label="Data de bloqueio por contrato"
              name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_BLOCK_DATE.name}
              defaultValue={
                defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_BLOCK_DATE.name] ?? null
              }
              rules={{}}
              className={styles.dateTimePicker}
              referaDatePickerProps={{ disabled: user?.activeProfile === 'intermediary' }}
            />
          </Accordion>
          <Accordion title="Informações Financeiras" className={styles.accordion}>
            <Input.Root
              name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name}
              defaultValue={
                defaultValues[AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name]
              }
            >
              <Input.Label
                labelClasses={styles.labelNumberInput}
                name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE}
              >
                Percentual do intermediário
              </Input.Label>
              <Input.ControllerNumber
                name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name}
                decimalScale={2}
              />
              <Input.ErrorMessage
                name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name}
              />
            </Input.Root>

            <Input.Root
              name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
              defaultValue={
                defaultValues[AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name]
              }
            >
              <Input.Label
                labelClasses={styles.labelNumberInput}
                name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
              >
                Percentual da Refera
              </Input.Label>
              <Input.ControllerNumber
                name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
                decimalScale={2}
              />
              <Input.ErrorMessage
                name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
              />
            </Input.Root>

            <Input.Root
              name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name}
              defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name]}
            >
              <Input.Label
                labelClasses={styles.labelNumberInput}
                name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name}
              >
                Valor mínimo para venda
              </Input.Label>
              <Input.ControllerNumber
                prefix="R$ "
                name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name}
              />
              <Input.ErrorMessage name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name} />
            </Input.Root>
            <FormControl fullWidth>
              <InputLabel id="payment-methods-label">
                Formas de pagamento aceitas pela intermediária
              </InputLabel>
              <Controller
                name={AGENCY_REFERA_DETAILS_FIELDS.PAYMENT_METHODS.name}
                control={methods.control}
                defaultValue={formattedPaymentMethods || []}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    labelId="payment-methods-label"
                    id="payment-methods"
                    multiple
                    value={selectedMethods || formattedPaymentMethods}
                    onChange={e => {
                      handleChange(e)
                    }}
                    label="Formas de pagamento aceitas pela intermediária"
                    renderValue={selected =>
                      selected
                        .map(
                          value =>
                            METHOD_PAYMENT_OPTIONS_SELECT.find(option => option.value === value)
                              .label
                        )
                        .join(', ')
                    }
                  >
                    {METHOD_PAYMENT_OPTIONS_SELECT.map(option => (
                      <MenuItem key={option?.value} value={option.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </MultiSelect>
                )}
              />
            </FormControl>
          </Accordion>
          <Accordion title="Informações de Contato" className={styles.accordion}>
            {user?.activeProfile === 'refera' && (
              <Select
                label="WhatsApp do vendedor"
                name={AGENCY_REFERA_DETAILS_FIELDS.SELLER_CONNECTION.name}
                options={whatsappConnectionOptions}
                getLabel={item => item.phone}
                getValue={item => item.id}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.SELLER_CONNECTION.name]}
              />
            )}
          </Accordion>
          <Accordion title="Configurações adicionais" className={styles.accordion}>
            <div className={styles.switchInputGroup}>
              <label
                className={styles.switchLabel}
                htmlFor={AGENCY_REFERA_DETAILS_FIELDS.SEND_NOTIFICATION.name}
              >
                Enviar Notificação
              </label>
              <Controller
                control={methods.control}
                name={AGENCY_REFERA_DETAILS_FIELDS.SEND_NOTIFICATION.name}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.SEND_NOTIFICATION.name]}
                render={props => (
                  <SwitchComponent
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>

            <div className={styles.switchInputGroup}>
              <label
                className={styles.switchLabel}
                htmlFor={AGENCY_REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE.name}
              >
                Definir responsável
              </label>
              <Controller
                control={methods.control}
                name={AGENCY_REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE.name}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE.name]}
                render={props => (
                  <SwitchComponent
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>

            <div className={styles.switchInputGroup}>
              <label
                className={styles.switchLabel}
                htmlFor={AGENCY_REFERA_DETAILS_FIELDS.REVIEW_BUDGET.name}
              >
                Revisar orçamento
              </label>
              <Controller
                control={methods.control}
                name={AGENCY_REFERA_DETAILS_FIELDS.REVIEW_BUDGET.name}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.REVIEW_BUDGET.name]}
                render={props => (
                  <SwitchComponent
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
            {/* </div> */}
            <div className={styles.row}>
              <div className={styles.switchInputGroup}>
                <div className={styles.switchField}>
                  <label
                    className={styles.switchLabel}
                    htmlFor={AGENCY_REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT.name}
                  >
                    Comunica desconto no aluguel
                  </label>
                  <Tooltip
                    arrow
                    disableFocusListener
                    disableTouchListener
                    title={
                      <Typography className={styles.tooltipText}>
                        Configura a disponibilidade de escolha do desconto no aluguel na comunicação
                        com os clientes finais, sem afetar a exibição nos dados de pagamento do
                        orçamento no Backoffice.
                      </Typography>
                    }
                    placement="bottom-end"
                  >
                    <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
                  </Tooltip>
                </div>

                <Controller
                  control={methods.control}
                  name={AGENCY_REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT.name]
                  }
                  render={props => (
                    <SwitchComponent
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.switchInputGroup}>
              <label
                className={styles.switchLabel}
                htmlFor={AGENCY_REFERA_DETAILS_FIELDS.PILOT_AUTO_TICKET.name}
              >
                Pilot Auto Ticket
              </label>
              <Controller
                control={methods.control}
                name={AGENCY_REFERA_DETAILS_FIELDS.PILOT_AUTO_TICKET.name}
                defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.PILOT_AUTO_TICKET.name]}
                render={props => (
                  <SwitchComponent
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
            <div className={styles.switchInputGroup}>
              <label
                className={styles.switchLabel}
                htmlFor={AGENCY_REFERA_DETAILS_FIELDS.WARRANTY_COMPENSATION.name}
              >
                Compensação pela garantia
              </label>
              <Controller
                control={methods.control}
                name={AGENCY_REFERA_DETAILS_FIELDS.WARRANTY_COMPENSATION.name}
                defaultValue={
                  defaultValues[AGENCY_REFERA_DETAILS_FIELDS.WARRANTY_COMPENSATION.name]
                }
                render={props => (
                  <SwitchComponent
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
            <FormControl fullWidth>
              <InputLabel id="agency-flows-label">Fluxos</InputLabel>
              <Controller
                name={AGENCY_REFERA_DETAILS_FIELDS.FLOWS.name}
                control={methods.control}
                defaultValue={[agency?.flows]}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    labelId="agency-flows-label"
                    name={AGENCY_REFERA_DETAILS_FIELDS.FLOWS.name}
                    id="agency-flows"
                    multiple
                    value={selectedFlows || agency?.flows}
                    onChange={handleChangeFlow}
                    label="Fluxos"
                    renderValue={selected =>
                      selected.length > 0
                        ? selected
                            .map(value => {
                              const selectedFlow = agency?.flows?.find(flow => flow.name === value)
                              return selectedFlow ? selectedFlow?.description : ''
                            })
                            .join(', ')
                        : 'Nenhum fluxo selecionado'
                    }
                  >
                    {agency?.flows
                      ?.slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(flow => (
                        <MenuItem key={flow.name} value={flow.name}>
                          {flow?.description}
                        </MenuItem>
                      ))}
                  </MultiSelect>
                )}
              />
            </FormControl>
          </Accordion>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default ReferaDataScreen
